import moment from 'moment'
import React from 'react'
import { Bell } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Grid, Header, Table } from 'semantic-ui-react'
import styled from 'styled-components'
import { handleAxiosError } from '../../actions/error'
import { sendReminder } from '../../actions/reminder'
import { SummaryDataItem } from '../../actions/startpage'
import { isInPageRenderMode } from '../../actions/util'
import { ModuleInfo } from '../../auth/modules'
import { useSort } from '../../hooks/sort'
import { getState } from '../../state'
import CustomConfirm from '../common/customConfirm'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import { useRowInfoFunction, RowInfoBasic, RowInfoFunction } from './rowInfo'

const StyledTable = styled(Table)`
    && tbody tr.negative,
    && tbody td.negative {
        color: #ff0000 !important;
        background-color: transparent !important;
        box-shadow: none !important;
    }
`

const TableSubTitleCell = styled(Table.HeaderCell)`
    text-align: center !important;
    padding: 5px !important;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const FlexGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  /* Default (large screens) */
  & > .column {
    flex: 0 0 calc(50% - 1rem); /* Two columns */
  }

  @media (max-width: 1650px) {
    & > .column {
      flex: 0 0 calc(100% - 1rem); /* Full-width on smaller screens */
    }
  }
`;

const Column = styled.div`
  padding: 1rem;
`;

const defaultHiddenColumns = ['linkId']

interface TableComponentProps {
    dataItem: SummaryDataItem
    getRowInfo: RowInfoFunction
    moduleId: number
    enableReminders: boolean
}

const TableComponent: React.FunctionComponent<TableComponentProps> = ({
    dataItem,
    getRowInfo,
    moduleId,
    enableReminders,
}) => {
    const {
        dispatch,
        state: { allUsers },
    } = getState()

    const isUserInactive = (subTitleKey: string, row: any) => {
        const info = getRowInfo(subTitleKey, moduleId, row)

        const userIdField =
            typeof info.reminderUserField! == 'function'
                ? info.reminderUserField!(row)
                : info.reminderUserField!

        const userId = row[userIdField]

        const user = allUsers.find(x => x.id === userId)

        if (!user) return true
        return !user.active
    }

    const handleSendReminder = (subTitleKey: string, row: any) => {
        const info = getRowInfo(subTitleKey, moduleId, row)

        const userField =
            typeof info.reminderUserField! == 'function'
                ? info.reminderUserField!(row)
                : info.reminderUserField!
        const reminderTemplate =
            typeof info.reminderTemplate! == 'function'
                ? info.reminderTemplate!(row)
                : info.reminderTemplate!
        const reminderTrackingSet =
            typeof info.reminderTrackingSet! == 'function'
                ? info.reminderTrackingSet!(row)
                : info.reminderTrackingSet!

        const parameters = info.reminderParameterFactory?.(row) ?? row

        sendReminder(
            row[userField],
            {
                templateKey: reminderTemplate,
                values: Object.fromEntries(
                    Object.entries(parameters).map(([prop, value]) => [prop, String(value)]),
                ),
            },
            reminderTrackingSet
                ? {
                    attachedId: row.linkId.toString(),
                    set: reminderTrackingSet,
                }
                : null,
        )
            .then(() => {
                toast(t('mailHasBeenSend'), {
                    type: 'success',
                })
            })
            .catch(handleAxiosError(dispatch))
    }

    const translateIfRequired = (
        translateColumns: string[] | undefined,
        columnName: string,
        columnContent: string,
    ) => {
        if ((translateColumns ?? []).indexOf(columnName) !== -1) return t(columnContent)

        return columnContent
    }

    const renderCell = (
        linkUrl: string,
        rowInfo: RowInfoBasic,
        row: any,
        column: unknown,
        propertyName: string,
    ) => {
        const getCellContent = () =>
            rowInfo.overrideCellInnerContent?.[propertyName] ? (
                rowInfo.overrideCellInnerContent[propertyName](row, column)
            ) : (
                <>
                    {translateIfRequired(rowInfo?.translateColumns, propertyName, column as string)}
                </>
            )

        if (rowInfo?.linkProperty === propertyName) {
            if (isInPageRenderMode() && !rowInfo?.bypassRouter)
                return <Link to={encodeURI(linkUrl)}>{getCellContent()}</Link>

            return <a href={linkUrl}>{getCellContent()}</a>
        } else {
            return getCellContent()
        }
    }

    const unboundRowInfo = getRowInfo(dataItem.subTitleKey, moduleId, {})
    const hiddenColumns = [
        ...defaultHiddenColumns,
        ...(unboundRowInfo.hiddenColumns ?? []),
        ...(unboundRowInfo.userIdField ?? []),
    ]

    if (unboundRowInfo.hideColumnIfNoValuesAreSet && dataItem.rows.length > 0) {
        Object.keys(dataItem.rows[0]).forEach(property => {
            if (!dataItem.rows.some(r => r[property])) {
                hiddenColumns.push(property)
            }
        })
    }

    const { t } = useTranslation()
    const { sortedRows, sortBy, sortDirection, sortProp } = useSort(dataItem.rows)

    return (
        <StyledTable key={dataItem.subTitleKey} compact sortable>
            <Table.Header>
                <Table.Row>
                    <TableSubTitleCell
                        colSpan={
                            dataItem.rows.length > 0 ? Object.values(dataItem.rows[0]).length : 1
                        }
                    >
                        {t(unboundRowInfo.overrideTitle ?? dataItem.subTitleKey)}
                    </TableSubTitleCell>
                </Table.Row>
                <Table.Row>
                    {dataItem.rows.length > 0
                        ? Object.keys(dataItem.rows[0])
                            .filter(x => hiddenColumns.indexOf(x) === -1)
                            .map(x => {
                                const unboundRowInfo = getRowInfo(
                                    dataItem.subTitleKey,
                                    moduleId,
                                    {},
                                )

                                return (
                                    <Table.HeaderCell
                                        key={x}
                                        sorted={sortProp === x ? sortDirection : undefined}
                                        onClick={() => sortBy(x)}
                                    >
                                        {t(unboundRowInfo.overrideHeaderString?.[x] ?? x)}
                                    </Table.HeaderCell>
                                )
                            })
                        : null}
                    {enableReminders ? <Table.HeaderCell /> : null}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {(sortedRows ?? []).map((row, i) => {
                    const rowInfo = getRowInfo(dataItem.subTitleKey, moduleId, row)

                    return (
                        <Table.Row
                            key={`row-${i}`}
                            negative={
                                (rowInfo.isNegative?.(row) ?? false) ||
                                    (row.doneBefore && moment(row.doneBefore) < moment())
                                    ? true
                                    : false
                            }
                        >
                            {Object.entries(row)
                                .filter(
                                    ([propertyName, _]) =>
                                        hiddenColumns.indexOf(propertyName) === -1,
                                )
                                .map(([propertyName, column]) => {
                                    const linkUrl =
                                        typeof rowInfo.linkUrl! == 'function'
                                            ? rowInfo.linkUrl!(row)
                                            : rowInfo.linkUrl!

                                    return (
                                        <Table.Cell key={`column-${propertyName}`}>
                                            {renderCell(
                                                linkUrl,
                                                rowInfo,
                                                row,
                                                column,
                                                propertyName,
                                            )}
                                        </Table.Cell>
                                    )
                                })}

                            {enableReminders && rowInfo.reminderTemplate ? (
                                <Table.Cell collapsing>
                                    <CustomConfirm
                                        trigger={
                                            <Button
                                                type="button"
                                                size="small"
                                                disabled={isUserInactive(dataItem.subTitleKey, row)}
                                                icon={<SemanticFeatherIcon FeatherIcon={Bell} />}
                                            />
                                        }
                                        onConfirm={() =>
                                            handleSendReminder(dataItem.subTitleKey, row)
                                        }
                                        text={t('areYouSureYouWishToSendReminder')}
                                    />
                                </Table.Cell>
                            ) : null}
                        </Table.Row>
                    )
                })}

                {dataItem.rows.length === 0 ? (
                    <Table.Row>
                        <Table.Cell textAlign="center">{t('noData')}</Table.Cell>
                    </Table.Row>
                ) : null}
            </Table.Body>
        </StyledTable>
    )
}

export interface TabPaneContentsProps {
    moduleInfo: ModuleInfo
    moduleId: number
    data: SummaryDataItem[]
    enableReminders: boolean
}

const TabPaneContents: React.FunctionComponent<TabPaneContentsProps> = ({
    moduleInfo,
    moduleId,
    data,
    enableReminders,
}) => {
    const getRowInfo = useRowInfoFunction()

    return (
        <Container>
            <Header
                image={`/images/moduleicons/${moduleInfo?.imageDark}`}
                content={moduleInfo.text}
            />
            <FlexGrid>
                {data
                    ?.filter(d => d.rows.length > 0)
                    .map(d => {
                        const rowInfo = getRowInfo(d.subTitleKey, moduleId, {});
                        return (
                            <Column className="column" key={`column-${d.subTitleKey}`}>
                                {rowInfo.overrideRenderComponent ? (
                                    rowInfo.overrideRenderComponent(d)
                                ) : (
                                    <TableComponent
                                        dataItem={d}
                                        enableReminders={enableReminders}
                                        moduleId={moduleId}
                                        getRowInfo={getRowInfo}
                                    />
                                )}
                            </Column>
                        );
                    })}
            </FlexGrid>
        </Container>
    )
}

export default TabPaneContents
