import React, { useMemo, useState } from 'react'
import { Button, Grid, GridColumn, GridRow, Header, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import {
    ColumnFiltersState, ExpandedState, GroupingState, PaginationState, SortingState, VisibilityState, createColumnHelper, getCoreRowModel,
    getExpandedRowModel,
    getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getGroupedRowModel, getPaginationRowModel, getSortedRowModel, useReactTable
} from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { Edit, UserPlus } from 'react-feather'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import TableReact from '../../../../wrappers/TableReact'
import useTableStorageBackedState from '../../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { IUserListViewDto } from '../../../../../api/skills'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../api'
import Loading from '../../../../common/Loading'
import { getDepartmentFromState, getLocationFromState } from '../../../../../state/helpers'
import { MultiDropdownFilter, multiFilter } from '../../../../wrappers/TableReact/Filters/MultiDropdownFilter'
import { DepartmentDisplay, LocationDisplay } from '../../../../common/globalStateDisplay'
import { getState } from '../../../../../state'
import ModalWrapper from '../../../../wrappers/ModalWrapper'
import CompetenceUserBatchForm from '../../../../forms/CompetenceUserBatchForm'
import { useNavigate } from 'react-router-dom'
import { globalFilterFn } from '../../../../wrappers/TableReact/Filters/GlobalFilter'
import ExternalUserList from './ExternalUserList'

const UserList = () => {
    const { t } = useTranslation()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, API.skills.getUsersListView, "UserList")
    const { state } = getState()
    const [showModal, setShowModal] = useState(false);
    const [showExternalUserModal, setShowExternalUserModal] = useState(false);

    const navigate = useNavigate()

    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('CompetenceUserListVisibilityState', {});
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('CompetenceUserListPaginationState', { pageSize: 10, pageIndex: 0 });
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('CompetenceUserListFilteringState', []);
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('CompetenceUserListSortingState', []);
    const [grouping, setGrouping] = useTableStorageBackedState<GroupingState>('CompetenceUserListGrouping', []);
    const [globalFilter, setGlobalFilter] = useTableStorageBackedState('CompetenceUserListGlobalFilterState', "");
    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("CompetenceUserListExpandedState", {})
    const hasLocations = state.locations?.length

    const columnHelper = createColumnHelper<IUserListViewDto>()
    const deviationTypeColumns = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                size: 55,
                enableHiding: false,
                enableGrouping: false,
                cell: ({ row }) => <Button title={t('edit')} type='button' onClick={() => navigate(`./${row.original.id}`)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
            }),
            columnHelper.accessor('name', {
                id: "name",
                header: t('name') as any,
            }),
            columnHelper.accessor((r) => r.departmentId ? getDepartmentFromState(state)(r.departmentId)?.name : "", {
                id: "departmentId",
                header: t('department') as any,
                filterFn: multiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                        <MultiDropdownFilter
                            setFilterValue={setFilterValue}
                            value={value}
                            options={state.departments.map(d => ({ value: d.id, text: d.name })) ?? []}
                        />
                    ),
                },
                cell: ({ row }) => <DepartmentDisplay departmentId={row.original.departmentId} />
            }),
            ...(
                hasLocations
                    ? [columnHelper.accessor((r) => r.locationId ? getLocationFromState(state)(r.locationId)?.name : "", {
                        id: "locationId",
                        header: `${t('locationId')}`,
                        filterFn: multiFilter,
                        meta: {
                            filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                                <MultiDropdownFilter
                                    setFilterValue={setFilterValue}
                                    value={value}
                                    options={state.locations?.map(d => ({ value: d.id, text: d.name })) ?? []}
                                />
                            ),
                        },
                        cell: ({ row }) => <LocationDisplay locationId={row.original.locationId} />
                    }),
                    ]
                    : []
            ),
            columnHelper.accessor((r) => r.positions.map(p => p.name ?? '').join(', '), {
                id: "positions",
                header: t('skillPositions') as any,
                size: 500,
                cell: ({ row }) => <span>{row.original.positions.map(p => p.name ?? '').join(', ')}</span>
            }),
            columnHelper.accessor((r) => r.skills.map(p => p.name ?? '').join(', '), {
                id: "skills",
                header: t('skills') as any,
                size: 600,
                cell: ({ row }) => <span>{row.original.skills.map(p => p.name ?? '').join(', ')}</span>
            }),
        ], [columnHelper, t, state, hasLocations, navigate])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: deviationTypeColumns,
        state: {
            columnVisibility,
            grouping,
            pagination,
            columnFilters,
            globalFilter,
            sorting,
            expanded
        },
        initialState: {
            globalFilter: "",
            columnFilters: [],
            grouping: [],
            sorting: [],
        },
        globalFilterFn: globalFilterFn,
        onGroupingChange: setGrouping,
        onExpandedChange: setExpanded,
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <FullHeightStackingContainer>
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn >
                        <Message info>
                            <Message.Header>
                                {t('skillsAdmin.userHeader')}
                            </Message.Header>
                            {t('skillsAdmin.userMessageBody')}
                        </Message>
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn >
                        <Header dividing>{t('users')}</Header>
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn >
                        <Button type='button' primary onClick={() => setShowExternalUserModal(true)} icon labelPosition="left">
                            <SemanticFeatherIcon
                                FeatherIcon={UserPlus}
                                size={'60%'}
                                centerIcon
                            />
                            {t('externalCompetenceUser')}
                        </Button>
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn >
                        <TableReact
                            onAddCallback={() => setShowModal(true)}
                            table={table}
                            overrideOnAddTitle={t('batchConnectUsers')}
                            canExport
                        />

                        <ModalWrapper title={t('batchConnectUsers')} open={showModal} onClose={() => setShowModal(false)} >
                            <CompetenceUserBatchForm onCancel={() => setShowModal(false)} refresh={refresh} />
                        </ModalWrapper>

                        <ModalWrapper title={t('externalCompetenceUser')} open={showExternalUserModal} onClose={() => setShowExternalUserModal(false)} >
                            <ExternalUserList refresh={refresh} />
                        </ModalWrapper>

                    </GridColumn>
                </GridRow>
            </Grid>



        </FullHeightStackingContainer>
    )
}

export default UserList
