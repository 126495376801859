import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../state';
import { handleAxiosError } from '../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as API from '../../../api'
import { IVirtualUserDto, IVirtualUserRequest } from '../../../api/skills';
import ControlInput from '../../fields/ControlInput';
import FormActions from '../FormActions';
import { allowedUserNameRegex } from '../../../utils/Validation';

export interface IExternalCompetenceUserFormProps {
    user?: IVirtualUserDto
    onCancel: () => void
    refresh: () => void
}

export const virtualUserRequestSchema = z.object({
    name: z.string()
        .min(1, "required")
        .refine(value => allowedUserNameRegex.test(value), {
            message: "usernameError",
        }),
    companyId: z.string().min(1, "required"),
});

const ExternalCompetenceUserForm = ({ user, onCancel, refresh }: IExternalCompetenceUserFormProps) => {
    const { t } = useTranslation();
    const {
        state,
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<IVirtualUserRequest>({
        defaultValues: user ? user : { name: "", companyId: state.company?.id.toString() },
        resolver: zodResolver(virtualUserRequestSchema),
    });

    const onVirtualUserRequest = async (data: IVirtualUserRequest) => {
        if (user) {
            return await API.skills.editVirtualUser(user.id, data)
        }
        return await API.skills.createVirtualUser(data)
    }


    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onVirtualUserRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn >
                        <ControlInput name="name" label={t('name')} control={control} required disabled={isSaving} />
                    </GridColumn>
                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default ExternalCompetenceUserForm;