import React from 'react'
import { Info, Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
    Button,
    Dimmer,
    Header,
    Loader,
    Message,
} from 'semantic-ui-react'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import MobileSystematicTaskTable from './MobileSystematicTaskTable'

const MobileSystematicTaskList = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div style={{ width: '100%' }}>
            <Dimmer active={false}>
                <Loader />
            </Dimmer>
            <Header content={t('mobileSystematicTasksHeader')} />
            <Message
                info
                icon={<SemanticFeatherIcon FeatherIcon={Info} size="32px" centerIcon={true} />}
                content={t('mobileSystematicTasksIntroText')}
            />
            <Button
                onClick={() => navigate('new')}
                icon
                labelPosition="left"
                primary
                type="button"
                floated='right'
            >
                <SemanticFeatherIcon
                    FeatherIcon={Plus}
                    size="60%"
                    centerIcon={true}
                />{' '}
                {t('addNew')}
            </Button>
            <MobileSystematicTaskTable />
        </div >
    )
}

export default MobileSystematicTaskList
