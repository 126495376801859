import { Controller, FieldValues } from "react-hook-form"
import { Control } from "react-hook-form"
import React from "react"
import DatePicker from 'react-datepicker'
import { FormField, Input } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import { endOfDay } from "date-fns"

interface IControlDateFieldProps<T extends FieldValues = any> {
    name: string
    label: string
    control: Control<T>
    disabled: boolean
    showWeekNumbers?: boolean
    showMonthDropdown?: boolean
    showYearDropdown?: boolean
    isClearable?: boolean
    required?: boolean
}

const ControlDateField = ({
    name,
    label,
    control,
    disabled,
    showWeekNumbers = true,
    showMonthDropdown = true,
    showYearDropdown = true,
    isClearable = false,
    required = false
}: IControlDateFieldProps) => {
    const { i18n, t } = useTranslation();
    const languageParts = i18n.language.split('-')
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField error={!!error} required={required}>
                    <label>{label}</label>
                    <DatePicker
                        selected={value}
                        value={value}
                        onChange={(date) => onChange(date ? endOfDay(date) : date)}
                        disabled={disabled}
                        isClearable={isClearable}
                        dateFormat="yyyy-MM-dd"
                        showWeekNumbers={showWeekNumbers}
                        showMonthDropdown={showMonthDropdown}
                        showYearDropdown={showYearDropdown}
                        customInput={<Input />}
                        autoComplete="off"
                        locale={languageParts[0]}
                        todayButton={t('today')}
                    />
                    {!!error &&
                        <div className="ui pointing above prompt label">
                            {t(`${error.message}`)}
                        </div>}
                </FormField>

            )}
        />
    )
}
export default ControlDateField