import React from 'react'
import { Routes, Route } from 'react-router'
import MySkills from './mobile/mySkils'
import { Grid } from 'semantic-ui-react'
import { isInPageRenderMode } from '../../../actions/util'
import SkillsMenu from './SkillsMenu'
import UserList from './admin/UserList'
import SkillList from './admin/SkillList'
import PositionList from './admin/PositionList'
import EditUserFormPage from './admin/UserList/EditUserFormPage'
import OrgChart from './admin/OrgChart'
import { ReactFlowProvider } from 'reactflow'

const SkillsModule: React.FunctionComponent = () => {
    return (
        <Grid
            stackable
            columns={isInPageRenderMode() ? 2 : 1}
            style={{ minHeight: '100%', flexGrow: 1 }}
        >
            <Grid.Row>
                {isInPageRenderMode() && (
                    <Grid.Column tablet={16} largeScreen={4} widescreen={3}>
                        <SkillsMenu />
                    </Grid.Column>
                )}
                <Grid.Column
                    tablet={16}
                    largeScreen={isInPageRenderMode() ? 12 : 16}
                    widescreen={isInPageRenderMode() ? 13 : 16}
                >
                    <Routes>
                        <Route index element={<ReactFlowProvider><OrgChart /></ReactFlowProvider>} />
                        <Route path="me" element={<MySkills />} />
                        <Route path="/admin/">
                            <Route path="users" element={<UserList />} />
                            <Route path={`users/:userId`} element={<EditUserFormPage />} />
                            <Route path="positions" element={<PositionList />} />
                            <Route path="skills" element={<SkillList />} />
                            {/* <Route path="educations" element={<h1>educations</h1>} />
                            <Route path="matrices" element={<h1>matrices</h1>} />
                            <Route path="engagementStatistics" element={<h1>engagementStatistics</h1>} /> */}
                        </Route>
                    </Routes>
                </Grid.Column>
            </Grid.Row>
        </Grid>

    )
}

export default SkillsModule