import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { IIdeaListDto, IImprovementGroupDto, IImprovementIdeaRequest, ImprovementIdeaDropdownStatus, ImprovementStatus } from "../../../api/improvements";
import { toast } from "react-toastify";
import * as API from '../../../api'
import { useUserOptionsOverride } from "../../../hooks/users";
import ControlDropdown from "../../fields/ControlDropdown";
import { useTranslation } from "react-i18next";
import ControlDateField from "../../fields/ControlDateField";
import { mapIdeaListDtoToImprovementIdeaRequest } from "../../../utils/Mappings/Improvements";
import { mockedImprovementIdeaRequest } from "../../../utils/MockedData/Improvements";
import Files, { UploadAllPendingFilesCallback } from "../../common/files";
import ControlPriorityMatrix from "../../fields/ControlPriorityMatrix";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from 'zod';
import { getState } from "../../../state";
import ControlTextArea from "../../fields/ControlTextArea";
import { Button, Form, FormField, Grid, GridColumn, GridRow, Modal } from "semantic-ui-react";
import SemanticFeatherIcon from "../../icons/SemanticFeatherIcon";
import { ArrowLeftCircle, Save } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useIsInRole } from "../../../hooks/role";
import { AdminRoles } from "../../../auth/roles";
import { handleAxiosError } from "../../../actions/error";
import SaveCancelPrintButtonGroup from "../../common/saveCancelPrintButtonGroup";
import PrintWrapper from "../../wrappers/PrintWrapper";
import ControlCheckbox from "../../fields/ControlCheckbox";

interface ImprovementIdeaFormProps {
    refresh: () => void
    improvementGroups: IImprovementGroupDto[]
    isNew: boolean
    selectedIdea?: IIdeaListDto
}

const ideaSchema = z.object({
    status: z.number({ invalid_type_error: "required" }),
    idea: z.string().min(1, { message: "required" }),
    groupId: z.coerce.number().optional().nullable(),
    expectedResult: z.string().optional().nullable(),
    solutionProposal: z.string().optional().nullable(),
    reporterId: z.string().min(1, { message: "required" }),
    created: z.date({ invalid_type_error: "required" }),
    responsibleId: z.string().optional().nullable(),
    plannedFinishedAt: z.date().optional().nullable(),
    priority: z.number().optional().nullable(),
    feedback: z.boolean().optional()
})
const ImprovementIdeaForm = ({ refresh, improvementGroups, isNew, selectedIdea }: ImprovementIdeaFormProps) => {
    const {
        dispatch,
        state: { userProfile },
    } = getState()
    const navigate = useNavigate()
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.ImprovementsAdmin)
    const printComponentRef = useRef()
    const [secondOpen, setSecondOpen] = React.useState(false)
    const { control, handleSubmit, setValue, reset, clearErrors, resetField, watch } = useForm<IImprovementIdeaRequest>({
        resolver: zodResolver(ideaSchema),
        defaultValues: selectedIdea ? mapIdeaListDtoToImprovementIdeaRequest(selectedIdea) : mockedImprovementIdeaRequest(userProfile)
    });
    const [uploadAllPendingFiles, setUploadAllPendingFiles] =
        useState<UploadAllPendingFilesCallback>()

    const { t } = useTranslation()
    const userOptions = useUserOptionsOverride(t, isNew ? u => u.active : undefined)
    const userOptionsWithActiveItemUser = useMemo(() => {
        if (
            selectedIdea?.reporterId &&
            !userOptions.find(({ value }) => selectedIdea.reporterId === value)
        ) {
            userOptions.push({
                key: selectedIdea.reporterId,
                value: selectedIdea.reporterId,
                text: `${t('userFromAnotherCompany')}`,
            })
        } else if (userProfile?.sub && !userOptions.find(({ value }) => value === userProfile?.sub)) {
            userOptions.push({
                key: userProfile?.sub,
                value: userProfile?.sub,
                text: `${t('userFromAnotherCompany')}`,
            })
        }

        return userOptions
    }, [userOptions, selectedIdea, t, userProfile])

    const addIdea = async (data: IImprovementIdeaRequest) => {
        const { status, feedback } = data;

        if (status === ImprovementStatus.Active) {
            const result = await API.improvements.activateIdea(selectedIdea!.id, data);
            if (feedback) {
                await API.improvements.notifyIdeaFeedback(selectedIdea!.id, { status });
            }
            return result;
        }

        if (isNew) {
            return await API.improvements.createIdea(data);
        }

        const result = await API.improvements.editIdea(selectedIdea!.id, data);
        if (feedback) {
            await API.improvements.notifyIdeaFeedback(selectedIdea!.id, { status: status! });
        }
        return result;
    };

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(addIdea, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            if (data) {
                if (uploadAllPendingFiles) await uploadAllPendingFiles((data.data as any).id?.toString())
            }
            toast.success(t("saved"))
            setSecondOpen(false)
            refresh()
            navigate(-1)
        },
    })

    const handleSetStatus = (value: number) => {
        setValue("status", value)
        if (value === ImprovementStatus.Active) setSecondOpen(true)
    }

    const handleCancleActivate = () => {
        setSecondOpen(false)
        resetField("status")
    }

    const handleDropdownOnChange = (key: keyof IImprovementIdeaRequest, value: any) => {
        setValue(key, value)
        clearErrors(key)
    }

    useEffect(() => {
        return () => reset()
    }, [reset])
    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} ref={printComponentRef} noValidate id="improvement-idea-form">
            <SaveCancelPrintButtonGroup
                location="top"
                onCancel={() => navigate(-1)}
                printComponentRef={printComponentRef}
            />
            <PrintWrapper title={t('improvementIdea')}>
                <Grid stackable>

                    <GridRow columns="2">
                        <GridColumn >
                            <ControlDropdown
                                name="status"
                                label={t('status')}
                                control={control}
                                popupText={!isAdmin ? t('adminFieldDisable') : ""}
                                disabled={isSaving || isNew || !isAdmin}
                                overrideOnChange={(value) => handleSetStatus(value)}
                                collection={Object.values(ImprovementIdeaDropdownStatus)
                                    .filter(value => typeof value === 'number')
                                    .map(d => ({ value: d, text: t(`improvementIdeaDropdownStatus.${d}`) }))}
                                required />
                        </GridColumn>

                        <GridColumn >
                            <ControlDateField
                                name="created"
                                label={t('reportedDate')}
                                control={control}
                                disabled={isSaving}
                                required />
                        </GridColumn>
                    </GridRow>

                    {!isNew && watch("status") !== 0 && watch("status") !== 5 && <GridRow columns={1}>
                        <GridColumn >
                            <ControlCheckbox control={control} disabled={isSaving} label={t('feedbackImprovementIdeaReporter')} name='feedback' />
                        </GridColumn>
                    </GridRow>}

                    <GridRow columns="2">
                        <GridColumn >
                            {improvementGroups.length ? <ControlDropdown
                                name="groupId"
                                label={t('groupId')}
                                control={control}
                                disabled={isSaving}
                                overrideOnChange={(value) => handleDropdownOnChange("groupId", value)}
                                collection={improvementGroups.map(d => ({ value: d.id, text: d.name }))} /> : <FormField />}
                        </GridColumn>

                        <GridColumn >
                            <ControlDropdown
                                name="reporterId"
                                label={t('reportedBy')}
                                required
                                control={control}
                                disabled={isSaving}
                                overrideOnChange={(value) => handleDropdownOnChange("reporterId", value)}
                                collection={userOptionsWithActiveItemUser} />
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn >
                            <ControlTextArea label={t('idea')} name="idea" control={control} disabled={isSaving} required />
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn >
                            <ControlTextArea label={t('expectedResult')} name="expectedResult" control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn >
                            <ControlTextArea label={t('suggestion')} name="solutionProposal" control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn >
                            <Files
                                displayTitle
                                module="ImprovementIdeas"
                                disableDelete={!isAdmin}
                                mayAutoUpload={!!selectedIdea}
                                enableDragDrop
                                collectionId={selectedIdea && selectedIdea.id.toString()}
                                onFilesAdded={selectedIdea ? undefined : (files, uploadAllPendingFiles) => {
                                    if (!files.length) return
                                    setUploadAllPendingFiles(() => uploadAllPendingFiles)
                                }}
                            />
                        </GridColumn>
                    </GridRow>


                    <Modal
                        onClose={handleCancleActivate}
                        open={secondOpen}
                        size='large'
                    >
                        <Modal.Header>{t('activateIdea')}</Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Grid stackable>
                                    <GridRow columns="2">
                                        <GridColumn >
                                            <ControlDropdown
                                                name="responsibleId"
                                                label={t('responsible')}
                                                control={control}
                                                disabled={isSaving}
                                                overrideOnChange={(value) => handleDropdownOnChange("responsibleId", value)}
                                                collection={userOptionsWithActiveItemUser} />
                                        </GridColumn>

                                        <GridColumn >
                                            <ControlDateField
                                                name="plannedFinishedAt"
                                                label={t('plannedFinishedAt')}
                                                control={control}
                                                disabled={isSaving} />
                                        </GridColumn>
                                    </GridRow>
                                    <GridRow columns={1}>
                                        <GridColumn >
                                            <ControlCheckbox control={control} disabled={isSaving} label={t('feedbackImprovementIdeaReporter')} name='feedback' />
                                        </GridColumn>
                                    </GridRow>
                                    <GridRow>
                                        <GridColumn >
                                            <ControlPriorityMatrix name="priority" control={control} showHeader />
                                        </GridColumn>
                                    </GridRow>
                                </Grid>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={handleCancleActivate} disabled={isSaving} icon labelPosition="left">
                                <SemanticFeatherIcon
                                    FeatherIcon={ArrowLeftCircle}
                                    size={'60%'}
                                    centerIcon

                                />{' '}
                                {t('cancel')}
                            </Button>
                            <Button
                                type="submit" form="improvement-idea-form" primary disabled={isSaving}
                                icon
                                labelPosition="left"
                            >
                                <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon />
                                {t('save')}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Grid >
            </PrintWrapper>
            <SaveCancelPrintButtonGroup
                location="bottom"
                onCancel={() => navigate(-1)}
                printComponentRef={printComponentRef}
            />
        </Form>

    );
};

export default ImprovementIdeaForm;
