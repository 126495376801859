import React, { useCallback, useState } from 'react';
import { Button, Grid, GridColumn, GridRow, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as API from '../../../../../../api'
import { useGetQueryByParams } from '../../../../../../hooks/useGetQueryByParams';
import Loading from '../../../../../common/Loading';
import FlexBox from '../../../../../common/flexBox';
import SemanticFeatherIcon from '../../../../../icons/SemanticFeatherIcon';
import { Edit, Plus, Trash2 } from 'react-feather';
import ModalWrapper from '../../../../../wrappers/ModalWrapper';
import { useDeleteExternalUser } from './useDeleteExternalUser';
import ExternalCompetenceUserForm from '../../../../../forms/ExternalCompetenceUserForm';
import { IVirtualUserDto } from '../../../../../../api/skills';

export interface IExternalUserListProps {
    refresh: () => void
}

interface IUserPositionListModalState {
    new: boolean
    edit: boolean
}

export type UserPositionType = "new" | "edit"

const ExternalUserList = ({ refresh }: IExternalUserListProps) => {
    const { t } = useTranslation();

    const { isDestroying, onDestroy } = useDeleteExternalUser(refresh)
    const [modalState, setModalState] = useState<IUserPositionListModalState>({
        new: false,
        edit: false,
    });
    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.skills.getVirtualUsers, "virtualUsers")
    const [selectedVirtualUser, setSelectedVirtualUser] = useState<IVirtualUserDto | null>(null)

    const handleShowModal = useCallback((data: IVirtualUserDto | null, modal: UserPositionType) => {
        setModalState({ ...modalState, [modal]: true })
        setSelectedVirtualUser(data)
    }, [modalState])

    const handleOnCancel = (modal: UserPositionType) => {
        setModalState({ ...modalState, [modal]: false })
        setSelectedVirtualUser(null)
    }

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <Grid stackable>
            <GridRow columns="1">
                <GridColumn>
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan="7" textAlign="right">
                                    <Button
                                        icon
                                        labelPosition="left"
                                        primary
                                        type="button"
                                        onClick={() => handleShowModal(null, "new")}
                                    >
                                        <SemanticFeatherIcon
                                            FeatherIcon={Plus}
                                            size={'60%'}
                                            centerIcon={true}
                                        />{' '}
                                        {t('new')}
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>{t('name')}</Table.HeaderCell>
                                <Table.HeaderCell collapsing />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {data?.map((x, i) => (
                                <Table.Row key={x.id}>
                                    <Table.Cell verticalAlign="top">
                                        {x.name}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign="top" collapsing>
                                        <FlexBox justifyContent="end">
                                            <Button
                                                icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                                                onClick={() => handleShowModal(x, "edit")}
                                                type="button"
                                                disabled={isDestroying}
                                            />
                                            <Button
                                                icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />}
                                                onClick={
                                                    () => onDestroy({
                                                        confirmText: t('confirm.deleteValue', { value: x.name }),
                                                        userId: x.id,
                                                    })
                                                }
                                                color='red'
                                                disabled={isDestroying}
                                                type="button"
                                            />
                                        </FlexBox>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </GridColumn>
            </GridRow>
            <ModalWrapper title={t('addValue', { value: t('externalCompetence').toLowerCase() })} open={modalState.new} onClose={() => handleOnCancel("new")}>
                <ExternalCompetenceUserForm onCancel={() => handleOnCancel("new")} refresh={refresh} />
            </ModalWrapper>


            {selectedVirtualUser &&
                <ModalWrapper title={t('editValue', { value: selectedVirtualUser.name })} open={modalState.edit} onClose={() => handleOnCancel("edit")}>
                    <ExternalCompetenceUserForm onCancel={() => handleOnCancel("edit")} refresh={refresh} user={selectedVirtualUser} />
                </ModalWrapper>}
        </Grid>
    );
};

export default ExternalUserList;