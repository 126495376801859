import React, { useCallback, useMemo, useState } from 'react'
import { Button, Header, Message, Popup } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { ColumnFiltersState, ExpandedState, PaginationState, Row, SortingState, VisibilityState, createColumnHelper, getCoreRowModel, getExpandedRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { ChevronDown, ChevronRight, Edit, Plus, Trash2 } from 'react-feather'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import TableReact, { countRowsWithSubRows, FooterLabel } from '../../../../wrappers/TableReact'
import useTableStorageBackedState from '../../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { IPositionHierarchyDto } from '../../../../../api/skills'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../api'
import Loading from '../../../../common/Loading'
import ModalWrapper from '../../../../wrappers/ModalWrapper'
import PositionForm from '../../../../forms/PositionForm'
import { useDeletePosition } from './useDeletePosition'
import MoveToRootDropTarget from './moveToRootDropTarget'
import { useMutation } from 'react-query'
import { handleAxiosError } from '../../../../../actions/error'
import { getState } from '../../../../../state'
interface IPositionListModalState {
    new: boolean
    newChild: boolean
    edit: boolean
}

export type PositionModalType = "new" | "newChild" | "edit"

const PositionList = () => {
    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, API.skills.getPositionHierarchy, "positionList")

    const [modalState, setModalState] = useState<IPositionListModalState>({
        new: false,
        newChild: false,
        edit: false,
    });
    const [selectedPosition, setSelectedPosition] = useState<IPositionHierarchyDto | null>(null);
    const [selectedTableRowId, setSelectedTableRowId] = useState<string | null>(null);
    const { isDestroying, onDestroy } = useDeletePosition(refresh)
    const handleShowModal = useCallback((data: IPositionHierarchyDto | null, modal: PositionModalType, tableRowId?: string) => {
        if (tableRowId) setSelectedTableRowId(tableRowId)
        if (data) {
            switch (modal) {
                case "newChild":
                    setModalState({ ...modalState, [modal]: true })
                    setSelectedPosition({ id: 0, name: "", description: "", parentId: data.id, subRows: [] })
                    break;
                case "edit":
                    setModalState({ ...modalState, [modal]: true })
                    setSelectedPosition(data)
                    break;
                default:
                    setModalState({ ...modalState })
                    setSelectedPosition(null)
                    break;
            }
            setModalState({ ...modalState, [modal]: true })
        } else {
            switch (modal) {
                case "new":
                    setModalState({ ...modalState, [modal]: true })
                    setSelectedPosition(null)
                    break;
                default:
                    setModalState({ ...modalState })
                    setSelectedPosition(null)
                    break;
            }
        }
    }, [modalState])

    const onCancel = (modal: PositionModalType) => {
        setModalState({ ...modalState, [modal]: false })
        setSelectedPosition(null)
    }

    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("positionListExpandedState", true)
    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('positionListVisibilityState', {});
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('positionListPaginationState', { pageSize: 10, pageIndex: 0 });
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('positionListFilteringState', []);
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('positionListSortingState', []);
    const onMoveRow = async (
        { draggedRow, targetRow }: { draggedRow: Row<IPositionHierarchyDto>, targetRow: Row<IPositionHierarchyDto> | null }
    ) => await API.skills.changePositionHierarchy({ id: draggedRow.original.id, parentId: targetRow?.original?.id || null })


    const { isLoading: isSaving, mutate: moveRow } = useMutation(onMoveRow, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            refresh()
        },
    })

    const handleDropRow = (draggedRow: Row<IPositionHierarchyDto>, targetRow: Row<IPositionHierarchyDto> | null) => {
        if (draggedRow.original.parentId === null && targetRow === null) {
            return
        }
        moveRow({ draggedRow, targetRow });
    };
    const columnHelper = createColumnHelper<IPositionHierarchyDto>()
    const deviationTypeColumns = useMemo(
        () => [
            columnHelper.display({
                id: "expandable",
                size: 55,
                enableHiding: false,
                cell: ({ row }) =>
                    <>
                        {row.getCanExpand() && (
                            <Button
                                icon={<SemanticFeatherIcon FeatherIcon={row.getIsExpanded() ? ChevronDown : ChevronRight} />}
                                type="button"
                                onClick={row.getToggleExpandedHandler()}
                            />
                        )}
                    </>
            }),
            columnHelper.accessor("name", {
                id: "name",
                size: 1000,
                header: t('name') as any,
                cell: ({ row, getValue }) => (
                    <div style={{ paddingLeft: `${row.depth * 4}rem`, }}>
                        {row.getCanExpand() ? (
                            <>

                                {getValue()} ({row.subRows.length})
                            </>
                        ) : getValue()}

                    </div>
                ),
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows;

                    if (filtered.length === 0) {
                        return null;
                    }

                    const totalCount = countRowsWithSubRows(filtered);

                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    );
                }
            }),
            columnHelper.display({
                id: "actions",
                size: 140,
                minSize: 140,
                maxSize: 140,
                enableHiding: false,
                cell: ({ row }) => {
                    const disableDelete = isDestroying || row.original.subRows?.length > 0
                    return (
                        <>
                            <Button
                                title={t('addNew')}
                                onClick={() => handleShowModal(row.original, "newChild", row.id)}
                                disabled={isDestroying}
                                type="button"
                                primary
                                icon={<SemanticFeatherIcon FeatherIcon={Plus} />} />

                            <Button title={t('edit')} type='button' onClick={() => handleShowModal(row.original, "edit")} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                            <Popup
                                content={disableDelete ? t('popupInfo.deletePosition') : t('delete')}
                                trigger={
                                    <span>
                                        <Button
                                            disabled={disableDelete}
                                            onClick={() => onDestroy({
                                                confirmText: `${t('confirm.deleteValue', { value: row.original.name })} ${t('confirm.deletePositionInfo')}`,
                                                id: row.original.id
                                            })}
                                            type='button'
                                            color='red'
                                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />
                                    </span>
                                }
                            />
                        </>
                    )
                }
            }),
        ], [columnHelper, t, handleShowModal, isDestroying, onDestroy])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: deviationTypeColumns,
        state: {
            columnVisibility,
            expanded,
            pagination,
            columnFilters,
            sorting
        },
        enableGlobalFilter: false,
        enableGrouping: false,
        onExpandedChange: setExpanded,
        getSubRows: row => row.subRows,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getExpandedRowModel: getExpandedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })


    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <FullHeightStackingContainer>
            <Message info>
                <Message.Header>
                    {t('skillsAdmin.positionHeader')}
                </Message.Header>
                {t('skillsAdmin.positionMessageBody')}
            </Message>
            <Header dividing>{t('skillPositions')}</Header>
            <MoveToRootDropTarget onDropRow={(draggedRow) => handleDropRow(draggedRow, null)} />
            <TableReact table={table}
                onAddCallback={() => handleShowModal(null, "new")}
                canExport={false}
                hideGroupRowValue={false}
                noRightBorder
                draggableRow
                onDropRow={handleDropRow}
            />

            <ModalWrapper title={t('addValue', { value: t('position').toLowerCase() })} open={modalState.new} onClose={() => onCancel("new")} >
                <PositionForm onCancel={() => onCancel("new")} refresh={refresh} modal='new' setExpanded={setExpanded}
                    selectedTableRowId={selectedTableRowId}
                    expanded={expanded} />
            </ModalWrapper>

            {selectedPosition && <ModalWrapper title={t('addValue', { value: t('position').toLowerCase() })} open={modalState.newChild} onClose={() => onCancel("newChild")} >
                <PositionForm onCancel={() => onCancel("newChild")} refresh={refresh} modal='newChild' position={selectedPosition} setExpanded={setExpanded}
                    selectedTableRowId={selectedTableRowId}
                    expanded={expanded} />
            </ModalWrapper>}

            {selectedPosition && <ModalWrapper title={t('editValue', { value: t('position').toLowerCase() })} open={modalState.edit} onClose={() => onCancel("edit")} >
                <PositionForm onCancel={() => onCancel("edit")} refresh={refresh} modal='edit' position={selectedPosition} setExpanded={setExpanded}
                    selectedTableRowId={selectedTableRowId}
                    expanded={expanded} />
            </ModalWrapper>}

        </FullHeightStackingContainer>
    )
}

export default PositionList


