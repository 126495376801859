import { Editor as EditorComponent } from '@tinymce/tinymce-react'
import { AxiosError } from 'axios'
import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components'
import { Editor } from 'tinymce/tinymce'
import { getAuthedAxios } from '../../../../../auth'

const EditorContainer = styled.div`
    min-height: 500px;
`

export interface EditDocumentProps {
    value?: string
    initialValue?: string
    onChange?: (text: string) => void
    readOnly?: boolean
    imageUploaded?: (fileId: string) => void
    onEditorInit?: (editor: Editor) => void
    imageUploadSettings?: ImageUploadSettings
}

export interface ImageUploadSettings {
    overrideCompanyId: number
}

const onFileUpload =
    (imageUploaded?: (fileId: string) => void, imageUploadSettings?: ImageUploadSettings) =>
        async (
            blobInfo: any,
            success: (url: string) => void,
            failure: (err: string, options?: any | undefined) => void,
            progress?: ((percent: number) => void) | undefined,
        ) => {
            const axios = await getAuthedAxios()

            const formData = new FormData()
            formData.append('file', blobInfo.blob(), blobInfo.filename())
            const uploadUrl = imageUploadSettings?.overrideCompanyId
                ? `${window.env.REACT_APP_FILE_SERVICE}/api/File/Documents/document-images?overrideCompanyId=${imageUploadSettings.overrideCompanyId}`
                : `${window.env.REACT_APP_FILE_SERVICE}/api/File/Documents/document-images`

            axios
                .post(uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: progressEvent => {
                        const percentageComplete = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        progress?.(percentageComplete)
                    },
                })
                .then(result => {
                    imageUploaded?.(result.data.id)
                    const imageUrl = `${window.env.REACT_APP_FILE_SERVICE}/api/File/${result.data.id}`
                    success(imageUrl)
                })
                .catch((x: AxiosError) => failure(x.message, x))
        }

const EditDocument: FunctionComponent<EditDocumentProps> = ({
    value,
    initialValue,
    onChange,
    imageUploaded,
    onEditorInit,
    imageUploadSettings,
    readOnly = false,
}) => {
    const { i18n } = useTranslation()
    const [editorLoaded, setEditorLoaded] = useState(false)

    const handleChange = (content: string) => {
        onChange?.(content)
    }

    return (
        <EditorContainer>
            <Dimmer active={!editorLoaded} inverted>
                <Loader />
            </Dimmer>

            <EditorComponent
                apiKey="lm80ja5wkxtullx74vaictayyplm5ci9kzswymg3tvqfwkwq"
                value={value}
                initialValue={initialValue}
                onEditorChange={handleChange}
                onInit={(_, editor) => {
                    setEditorLoaded(true)
                    onEditorInit?.(editor)
                }}
                disabled={readOnly}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists image imagetools',
                        'searchreplace visualblocks code fullscreen',
                        'table paste help wordcount',
                        'link',
                    ],
                    toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic underline backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'table link unlink image |' +
                        'removeformat | help code',
                    content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    entity_encoding: 'raw',

                    automatic_uploads: false,
                    images_upload_handler: onFileUpload(imageUploaded, imageUploadSettings),
                    file_picker_types: 'image',
                    images_file_types: 'jpeg,jpg,jpe,png,gif,bmp,webp',
                    images_reuse_filename: false,

                    language: i18n.language === 'sv' ? 'sv_SE' : 'en_US',
                    language_url: i18n.language === 'sv' ? '/tinymce-langs/sv_SE.js' : undefined,

                    browser_spellcheck: true,
                    contextmenu: false,
                }}
            />
        </EditorContainer>
    )
}

export default EditDocument
