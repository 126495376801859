import React from 'react'
import { Route, Routes } from 'react-router'
import EditMobileSystematicTask from './EditMobileSystematicTask'
import MobileSystematicTaskList from './MobileSystematicTaskList'

const SystematicTasksModule = () => {
    return (
        <Routes>
            <Route path={'/'} element={<MobileSystematicTaskList />} />
            <Route path={'new'} element={<EditMobileSystematicTask />} />
            <Route path={'edit/:id'} element={<EditMobileSystematicTask />} />
        </Routes>
    )
}

export default SystematicTasksModule
