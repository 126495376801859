import { Document, Page, Path, Svg, Text, View, Image } from '@react-pdf/renderer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldDataItem, MobileSystematicTaskFieldDataType } from '../../EditMobileSystematicTask'
import { formatDate } from '../../../../actions/util'

const renderDynamicHTML = (htmlData: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlData, "text/html");

    const traverseNodes = (nodes: any) => {
        return Array.from(nodes).map((node: any, index) => {
            if (node.nodeName === "P") {
                const img = node.querySelector("img");
                const imgElement = img ? (
                    <Image
                        key={`img-${index}`}
                        src={img.src}
                        style={{
                            width: Number(img.width),
                            height: Number(img.height),
                            marginRight: 5,
                        }}
                    />
                ) : null;
                const childContent = traverseNodes(node.childNodes);
                return (
                    <View
                        key={index}
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 5,
                        }}
                    >
                        {imgElement}
                        <Text style={{ flexWrap: "wrap" }}>{childContent}</Text>
                    </View>
                );
            }

            if (node.nodeName === "UL") {
                return (
                    <View key={index} style={{ marginLeft: 20 }}>
                        {traverseNodes(node.childNodes)}
                    </View>
                );
            }

            if (node.nodeName === "LI") {
                const img = node.querySelector("img");
                const imgElement = img ? (
                    <Image
                        key={`img-${index}`}
                        src={img.src}
                        style={{
                            width: Number(img.width),
                            height: Number(img.height),
                            marginRight: 5,
                        }}
                    />
                ) : null;

                // Bearbeta alla barnnoder till LI
                const childContent = traverseNodes(node.childNodes);

                return (
                    <View
                        key={index}
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 5,
                        }}
                    >
                        <Text style={{ fontWeight: "bold", marginRight: 5 }}>•</Text>
                        {imgElement}
                        <Text style={{ flexWrap: "wrap" }}>{childContent}</Text>
                    </View>
                );
            }

            if (node.nodeName === "TABLE") {
                return (
                    <View
                        key={index}
                        style={{
                            borderWidth: 1,
                            borderColor: "black",
                            marginVertical: 10,
                        }}
                    >
                        {traverseNodes(node.childNodes)}
                    </View>
                );
            }

            if (node.nodeName === "TR") {
                return (
                    <View
                        key={index}
                        style={{
                            flexDirection: "row",
                            borderBottomWidth: 1,
                            borderBottomColor: "black",
                        }}
                    >
                        {traverseNodes(node.childNodes)}
                    </View>
                );
            }

            if (node.nodeName === "TD") {
                return (
                    <View
                        key={index}
                        style={{
                            flex: 1,
                            padding: 5,
                            borderRightWidth: 1,
                            borderRightColor: "black",
                        }}
                    >
                        {traverseNodes(node.childNodes)}
                    </View>
                );
            }

            if (node.nodeName === "STRONG") {
                return (
                    <Text
                        key={index}
                        style={{
                            fontFamily: "Helvetica-Bold",
                            marginBottom: 5,
                        }}
                    >
                        {node.textContent + " "}
                    </Text>
                );
            }
            if (node.nodeType === Node.TEXT_NODE) {
                const text = node.textContent?.trim();
                if (text) {
                    return (
                        <Text key={index}>
                            {text}
                        </Text>
                    );
                }
            }

            if (node.nodeName === "EM") {
                return (
                    <Text key={index} style={{ fontStyle: "italic", marginBottom: 5 }}>
                        {node.textContent}
                    </Text>
                );
            }

            return (
                <Text key={index}>
                    {node.textContent}
                </Text>
            );
        });
    };

    return traverseNodes(doc.body.childNodes);
};


export interface MobileSystematicTaskPdfProps {
    data: PdfDocumentData | undefined
}

export interface PdfDocumentData {
    title: string
    responsibleId: string
    date: string
    description: string
    fields: FieldDataItem[]
}

const MobileSystematicTaskPdf = ({ data }: MobileSystematicTaskPdfProps) => {
    const { t } = useTranslation()

    if (!data) {
        return <></>
    }
    const descriptionHtml = `<html><body>${data.description}</body></html>`

    return (
        <Document>
            <Page
                size="A4"
                style={{
                    paddingTop: 65,
                    paddingBottom: 65,
                    paddingHorizontal: 50,
                    fontSize: 12,
                    fontFamily: 'Helvetica',
                }}
                wrap
            >
                <Text style={{ fontSize: 24, fontFamily: 'Helvetica-Bold', marginBottom: 20 }}>
                    {data.title}
                </Text>
                <Text style={{ fontSize: 12, fontFamily: 'Helvetica-Bold', marginBottom: 5 }}>
                    {t('taskDate')}
                </Text>
                <Text style={{ fontSize: 12, marginBottom: 20 }}>{formatDate(data.date)}</Text>
                <View style={{ marginBottom: 20 }}>{renderDynamicHTML(descriptionHtml)}</View>
                <br />
                <br />
                <br />
                <br />

                {data.fields.map((field, index) => {
                    return (
                        <View
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                marginBottom: 20,

                                paddingBottom: 20,
                                borderBottom: '1px solid #ccc',
                            }}
                            wrap={false}
                        >
                            {field.type ===
                                MobileSystematicTaskFieldDataType.CheckboxWithComment && (
                                    <>
                                        <View style={{ flex: 3 }}>
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: 10,
                                                    alignItems: 'center',
                                                    marginTop: -4,
                                                }}
                                            >
                                                {field.checked ? (
                                                    <Svg width="20" height="20" viewBox="0 0 24 24">
                                                        <Path
                                                            fill="rgb(0,0,0)"
                                                            d="M19,19H5V5H15V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V11H19M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"
                                                        />
                                                    </Svg>
                                                ) : (
                                                    <Svg width="20" height="20" viewBox="0 0 24 24">
                                                        <Path
                                                            fill="rgb(0,0,0)"
                                                            d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
                                                        />
                                                    </Svg>
                                                )}

                                                <Text
                                                    style={{
                                                        fontFamily: 'Helvetica-Bold',
                                                        maxWidth: 270,
                                                    }}
                                                >
                                                    {field.label}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <Text
                                                style={{
                                                    fontFamily: 'Helvetica-Bold',
                                                    fontSize: 11,
                                                    marginBottom: 5,
                                                }}
                                            >
                                                {t('conditionalEffects')}
                                            </Text>
                                            <Text>{field.value}</Text>
                                        </View>
                                    </>
                                )}

                            {field.type ===
                                MobileSystematicTaskFieldDataType.MultipleUserDropdown && (
                                    <View>
                                        <Text style={{ marginBottom: 5, fontFamily: 'Helvetica-Bold' }}>
                                            {field.label}
                                        </Text>
                                        {(field?.value as string[])?.map((value: string, i: number) => (
                                            <Text key={i} style={{ marginBottom: 5 }}>
                                                {value}
                                            </Text>
                                        ))}
                                    </View>
                                )}

                            {field.type === MobileSystematicTaskFieldDataType.Date && (
                                <View>
                                    <Text style={{ marginBottom: 5, fontFamily: 'Helvetica-Bold' }}>
                                        {field.label}
                                    </Text>

                                    <Text style={{ marginBottom: 5 }}>
                                        {formatDate(field.value as string)}
                                    </Text>
                                </View>
                            )}

                            {(field.type === MobileSystematicTaskFieldDataType.Number ||
                                field.type === MobileSystematicTaskFieldDataType.String) && (
                                    <View>
                                        <Text
                                            style={{
                                                marginBottom: 5,
                                                fontFamily: 'Helvetica-Bold',
                                            }}
                                        >
                                            {field.label}
                                        </Text>

                                        <Text style={{ marginBottom: 5 }}>{field.value}</Text>
                                    </View>
                                )}
                        </View>
                    )
                })}
            </Page>
        </Document>
    )
}

export default MobileSystematicTaskPdf
